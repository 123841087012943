import React, {useState} from 'react';
import './App.css';
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {db} from "./db";
import {useLiveQuery} from "dexie-react-hooks";

function App() {

  const [result, setResult] = useState('');

  const currentUploads = useLiveQuery(
    () => db.uploads.toArray()
  );

  const size = currentUploads ? currentUploads.reduce((sum, row) => {return sum + row.file.size}, 0) : 0;

  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          PWA upload test
        </Typography>
        {result ? <Alert severity="success">{result}</Alert> : null}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Button
            variant="outlined"
            component="label"
          >
            Select Files
            <input
              type="file"
              multiple
              hidden
              onChange={e => {
                // console.log(e.currentTarget.files);
                const files = e.currentTarget.files;
                [...files].forEach(file => {
                  db.uploads.add({
                    name: file.name,
                    file
                  });
                })
              }}
            />
          </Button>&nbsp;
          <Button variant="contained" onClick={async () => {
            const data = new FormData();
            currentUploads.forEach(row => {
              data.append('files[]', row.file, row.name);
            })
            const response = await fetch('upload.php', {
              method: 'POST',
              body: data,
            })
            const result = await response.text();
            setResult(result);
          }}>Upload</Button>
        </Box>
        <Box style={{marginTop: 20}}>
          <Typography>
            Size sum {size}
          </Typography>
        </Box>
        {currentUploads ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUploads.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.file.size}
                    </TableCell>
                    <TableCell>
                      <img alt={row.name} src={URL.createObjectURL(row.file)} style={{maxHeight: 200}}/>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => {db.uploads.delete(row.id)}}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

      </Box>
    </Container>
  );
}

export default App;
